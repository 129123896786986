<template>
  <v-row align="center" class="fill-height">
    <v-col class="mt-4" cols="12" v-if="cards.length > 0">
      <v-slide-group
        v-model="activeCard"
        mandatory
        show-arrows="always"
        center-active
      >
        <template v-slot:next>
          <v-btn
            icon
            @click="incrementCard"
            v-if="activeCard < cards.length - 1"
          >
            <v-icon size="50" color="primary"> mdi-chevron-right </v-icon>
          </v-btn>
        </template>
        <template v-slot:prev>
          <v-btn icon @click="decrementCard" v-if="activeCard > 0">
            <v-icon size="50" color="primary"> mdi-chevron-left </v-icon>
          </v-btn>
        </template>
        <v-slide-item
          v-for="card in orderedCards"
          :key="card.id"
          v-slot:default="{ active, toggle }"
          :class="cards.some(x => x.impact) ? 'mt-7' : ''"
        >
          <recommendations-card
            class="mr-4 ml-1 mb-2"
            :width="cardWidth"
            :id="card.id"
            :assessmentId="assessmentId"
            :imageName="card.json.imageName"
            :heading="
              card.heading ? card.heading(results) : $t(card.json.heading)
            "
            :subheading="$t(card.json.subheading)"
            :explanation="
              card.explanation
                ? card.explanation(results)
                : $t(card.json.explanation)
            "
            :type="card.json.type"
            :link="card.json.link"
            :route="card.json.route"
            :previouslyLiked="likedCards.includes(card.id)"
            :coveredBy="card.json.coveredBy"
            :impact="card.impact"
            :linkType="card.linkType(results)"
            slideGroup
          />
        </v-slide-item> </v-slide-group
    ></v-col>

    <v-col v-else cols="12">
      <v-row justify="center" align="center" style="width: 100%">
        <v-col cols="10">
          <v-img
            :src="
              require('@/assets/images/burnoutassessment/' +
                category +
                'Default.svg')
            "
            max-width="900px"
            contain
          />
        </v-col>
      </v-row>
      <p class="text-h5 mt-6 text-center">
        {{ $t("burnoutReportRecommendations.noRecommendations") }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import RecommendationsCard from "@/components/common/report/cards/RecommendationsCard";
import { displayedExplanationText } from "@/services/recommendations/recommendations-service.js";
import { getLikedItemsForAssessment } from "@/customApi";

export default {
  name: "RecommendationsPanel",
  props: {
    assessmentId: String,
    cards: Array,
    category: String,
    results: {}
  },
  components: {
    RecommendationsCard
  },
  data() {
    return {
      likedCards: [],
      activeCard: 0
    };
  },
  async mounted() {
    try {
      this.likedCards = await getLikedItemsForAssessment(this.assessmentId);
    } catch (err) {}
  },
  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
        case "sm":
          return "300px";
        case "xs":
          return "180px";
        default:
          return "400px";
      }
    },
    orderedCards() {
      this.cards.sort((a, b) => {
        return !a.impact || a.impact < b.impact ? 1 : -1;
      });
      return this.cards;
    }
  },
  methods: {
    displayedExplanationText,
    incrementCard() {
      if (this.activeCard < this.cards.length - 1) {
        this.activeCard++;
      }
    },
    decrementCard() {
      if (this.activeCard > 0) {
        this.activeCard--;
      }
    }
  }
};
</script>
