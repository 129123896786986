var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "fill-height", attrs: { align: "center" } },
    [
      _vm.cards.length > 0
        ? _c(
            "v-col",
            { staticClass: "mt-4", attrs: { cols: "12" } },
            [
              _c(
                "v-slide-group",
                {
                  attrs: {
                    mandatory: "",
                    "show-arrows": "always",
                    "center-active": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "next",
                        fn: function() {
                          return [
                            _vm.activeCard < _vm.cards.length - 1
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: { click: _vm.incrementCard }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { size: "50", color: "primary" }
                                      },
                                      [_vm._v(" mdi-chevron-right ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "prev",
                        fn: function() {
                          return [
                            _vm.activeCard > 0
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: { click: _vm.decrementCard }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { size: "50", color: "primary" }
                                      },
                                      [_vm._v(" mdi-chevron-left ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    106123993
                  ),
                  model: {
                    value: _vm.activeCard,
                    callback: function($$v) {
                      _vm.activeCard = $$v
                    },
                    expression: "activeCard"
                  }
                },
                _vm._l(_vm.orderedCards, function(card) {
                  return _c("v-slide-item", {
                    key: card.id,
                    class: _vm.cards.some(function(x) {
                      return x.impact
                    })
                      ? "mt-7"
                      : "",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var active = ref.active
                            var toggle = ref.toggle
                            return [
                              _c("recommendations-card", {
                                staticClass: "mr-4 ml-1 mb-2",
                                attrs: {
                                  width: _vm.cardWidth,
                                  id: card.id,
                                  assessmentId: _vm.assessmentId,
                                  imageName: card.json.imageName,
                                  heading: card.heading
                                    ? card.heading(_vm.results)
                                    : _vm.$t(card.json.heading),
                                  subheading: _vm.$t(card.json.subheading),
                                  explanation: card.explanation
                                    ? card.explanation(_vm.results)
                                    : _vm.$t(card.json.explanation),
                                  type: card.json.type,
                                  link: card.json.link,
                                  route: card.json.route,
                                  previouslyLiked: _vm.likedCards.includes(
                                    card.id
                                  ),
                                  coveredBy: card.json.coveredBy,
                                  impact: card.impact,
                                  linkType: card.linkType(_vm.results),
                                  slideGroup: ""
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          )
        : _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                {
                  staticStyle: { width: "100%" },
                  attrs: { justify: "center", align: "center" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "10" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/images/burnoutassessment/" +
                            _vm.category +
                            "Default.svg"),
                          "max-width": "900px",
                          contain: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("p", { staticClass: "text-h5 mt-6 text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("burnoutReportRecommendations.noRecommendations")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }